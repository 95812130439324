module.exports = [{
      plugin: require('/opt/build/repo/node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"contextualobservation"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Open Sans"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
