// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("/opt/build/repo/src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-e-commerce-js": () => import("/opt/build/repo/src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-education-js": () => import("/opt/build/repo/src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-transport-js": () => import("/opt/build/repo/src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */)
}

